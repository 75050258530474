/* height: "40px",
      backgroundColor: "green",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      marginBottom:"5px", */

#typo{
height: 40px;
background-color: whitesmoke;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;
margin-bottom: 5px;
color: black;
cursor: pointer;
}

#typo:hover{
    background-color: red;
    color: white;
}
