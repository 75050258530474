.editable-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .editable-table th, .editable-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .editable-table th {
    background-color: #f2f2f2;
  }
  
  .add-form input, .add-form button, .editable-table input, .editable-table button {
    margin: 5px 0;
    padding: 5px;
  }
  
  button {
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
  }
  
  button:hover {
    opacity: 0.8;
  }
  