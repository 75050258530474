.header{
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
}

.headergrid{
    width: 95%;
    display: flex;
    justify-content: left;
}

.logo{
height: 3em;
width: 10em;
}