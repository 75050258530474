.editable-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .editable-table th, .editable-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left; 
  }
  
  .editable-table th {
    background-color: #1488CC;
    color: white;
  }